<template>
  <div>
    <v-alert text dense color="info" border="left" style="font-size: 14px">
      {{ $t("labels.return_receive_note_1") }} <br />
      {{ $t("labels.return_receive_note_2") }} <br />
      <!-- 3. Xóa đơn nhận hoàn: SSC-RMRT-SSC → Tracking / Mã vận chuyển → SSC-RMRT-SSC <br> -->
    </v-alert>
    <v-row dense>
      <v-col cols="8" md="10">
        <v-text-field
          :label="$t('labels.tracking_or_delivery_order_id')"
          dense
          outlined
          clearable
          hide-details
          autofocus
          @keyup.enter="getOrder"
          v-model="tracking"
          class="c-input-small"
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          color="warning"
          style="height: 40px"
          block
          @click="showConfirmRemove"
        >
          {{ $t("labels.remove_order") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table
      class="mb-5"
      v-if="groupOrderByDelivery && groupOrderByDelivery.length > 0"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_company") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 135px">
              {{ $t("labels.order_return") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 135px">
              {{ $t("labels.lost_seal") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 120px">
              {{ $t("labels.missing") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 120px">
              {{ $t("labels.is_damage") }}
            </th>
            <th role="columnheader" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(group, gk) in groupOrderByDelivery"
            :key="`${group.name}_${gk}`"
            class="text-center"
          >
            <td>{{ group.name }}</td>
            <td>{{ group.total }}</td>
            <td>
              <div :class="{ 'gr-error-count': group.lost_seal != 0 }">
                {{ group.lost_seal }}
              </div>
            </td>
            <td>
              <div :class="{ 'gr-error-count': group.missing != 0 }">
                {{ group.missing }}
              </div>
            </td>
            <td>
              <div :class="{ 'gr-error-count': group.damage != 0 }">
                {{ group.damage }}
              </div>
            </td>
            <td style="width: 220px">
              <!-- border-bottom: 0; :rowspan="groupOrderByDelivery.length" v-if="gk === 0" -->
              <v-btn
                color="warning"
                class="mr-2"
                small
                @click.prevent="cancelGroup(group)"
                outlined
              >
                {{ $t("labels.redo") }}
              </v-btn>
              <v-btn
                color="success"
                :disabled="group.is_not_checked > 0"
                small
                @click.prevent="confirmGroup(group)"
              >
                {{ $t("labels.confirm") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="mb-5" v-if="orders && orders.length > 0">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.tracking") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.lost_seal") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.missing") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.is_damage") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.customer") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_order_id") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.warehouse_state") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_partner_state") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_state") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="od in reverseOrders"
            :key="od.tracking_id"
            class="text-center"
          >
            <td>
              <v-btn
                :color="od.is_lost_seal && !od.is_checked ? 'red' : 'primary'"
                outlined
                @click="showDialogCheckingOrder(od)"
              >
                {{ od.tracking_id }}
              </v-btn>
            </td>
            <td>
              <v-switch
                class="switch-center"
                v-model="od.is_lost_seal"
                readonly
                disabled
              ></v-switch>
            </td>
            <td>{{ od.missing }}</td>
            <td>{{ od.damage }}</td>
            <td>{{ od.customer_company_name }}</td>
            <td>{{ od.short_delivery_order_id || od.delivery_order_id }}</td>
            <td>{{ $t(`labels.order_status_${od.state}`) }}</td>
            <td>{{ od.delivery_partner_state }}</td>
            <td>{{ $t(`labels.delivery_status_${od.id_delivery_state}`) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider class="my-10"></v-divider>
    <ReturnReceiveHistory />

    <v-dialog v-model="dialogCheckingOrder" persistent max-width="1200px">
      <DialogCheckingReceive
        v-if="dialogCheckingOrder && checkingOrder && checkingOrder.id"
        :checking-order="checkingOrder"
        @closeDialog="closeDialogCheckingOrder"
        @submit="submitDialogCheckingOrder"
      />
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('messages.lost_seal_order')"
        :description="
          $t('labels.lost_seal_order_confirm', {
            tracking: lastOrderScanned.tracking_id,
          })
        "
        :label="$t('labels.tracking_or_delivery_order_id')"
        :placeholder="$t('labels.confirm_return_import_note')"
        :codes="checkingCodes"
        @cancel="cancelConfirm"
        @confirm="confirmLostSeal"
      />
    </v-dialog>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBoxNew
        v-if="dialogConfirmRemove"
        :title="$t('labels.remove_return_receive')"
        :description="$t('messages.scan_confirm_remove_receive_return')"
        :label="$t('messages.tracking_delivery_confirmation')"
        :placeholder="$t('messages.tracking_delivery_confirmation')"
        :codes="removeCodes"
        @cancel="cancelConfirmRemove"
        @confirm="confirmRemove"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { BARCODE_MAT_NIEM_PHONG, BARCODE_REMOVE } from "@/libs/const";
import { downloadExcelFile } from "@/libs/helpers";
import moment from "moment";

export default {
  name: "Receive",
  components: {
    DialogCheckingReceive: () =>
      import("@/components/goods_return/DialogCheckingReceive"),
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
    ConfirmRemoveBoxNew: () =>
      import("@/components/common/ConfirmRemoveBoxNew"),
    ReturnReceiveHistory: () =>
      import("@/components/goods_return/ReturnReceiveHistory"),
  },
  data: () => ({
    tracking: null,
    confirm_tracking: null,
    orders: [],
    checkingOrder: {},
    dialogCheckingOrder: false,
    isLoading: false,
    is_lost_seal: false,
    isDamage: false,
    dialogConfirm: false,
    dialogConfirmRemove: false,
  }),
  computed: {
    groupOrderByDelivery() {
      const groups = {};
      this.orders.forEach((order) => {
        if (!groups[order.delivery_short_name]) {
          groups[order.delivery_short_name] = {
            name: order.delivery_short_name,
            id_delivery_company: order.id_delivery_company,
            total: 0,
            lost_seal: 0,
            is_not_checked: 0,
            missing: 0,
            damage: 0,
            orders: [],
          };
        }
        groups[order.delivery_short_name].total += 1;
        groups[order.delivery_short_name].lost_seal += order.is_lost_seal
          ? 1
          : 0;
        groups[order.delivery_short_name].missing += order.missing || 0;
        groups[order.delivery_short_name].damage += order.damage || 0;
        groups[order.delivery_short_name].is_not_checked +=
          order.is_lost_seal && !order.is_checked ? 1 : 0;

        const orderItems = [...order.items];
        const orderNotes = [];
        if (order.items && order.items.length > 0) {
          for (let j = 0; j < order.items.length; j++) {
            const o = orderItems[j];
            let ons = [];
            if (order.is_lost_seal || order.missing || order.damage) {
              ons = [
                `SKU: ${o.sku}`,
                `${this.$t("labels.export")}: ${o.quantity}`,
                `${this.$t("labels.return_1")}: ${
                  order.is_checked
                    ? o.receive_quantity > o.quantity
                      ? o.quantity
                      : o.receive_quantity
                    : o.quantity
                }`,
                `${this.$t("labels.missing")}: ${
                  order.is_checked
                    ? o.quantity - o.receive_quantity > 0
                      ? o.quantity - o.receive_quantity
                      : 0
                    : 0
                }`,
                `${this.$t("labels.is_damage")}: ${
                  order.is_checked ? o.damage : 0
                }`,
              ];
            }
            if (o.reason) {
              if (typeof o.reason === "string") {
                ons.push(`${this.$t("labels.note")}: ${o.reason}`);
              } else {
                ons.push(`${this.$t("labels.note")}: ${o.reason.join(", ")}`);
              }
            }
            if (o.note) {
              ons.push(`${o.note}`);
            }
            const oNote = ons.join(", ");
            orderNotes.push(oNote);
          }
        }
        groups[order.delivery_short_name].orders.push({
          id: order.id,
          tracking_id: order.tracking_id,
          is_lost_seal: order.is_lost_seal,
          is_wet_or_damaged: order.damage && order.damage > 0 ? 1 : 0,
          note: orderNotes.join(`; \n`),
        });
      });
      return Object.values(groups);
    },
    lastOrderScanned() {
      if (this.orders.length === 0) {
        return {};
      }
      return this.orders[this.orders.length - 1];
    },
    removeCodes() {
      // return [BARCODE_REMOVE]
      const codes = [];
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];

        if (order.tracking_id) {
          codes.push(order.tracking_id);
        }
        if (order.delivery_order_id) {
          codes.push(order.delivery_order_id);
        }
        if (order.short_delivery_order_id) {
          codes.push(order.short_delivery_order_id);
        }
        if (order.return_order_id) {
          codes.push(order.return_order_id);
          const returnOrderIdNoS = order.return_order_id.slice(0, -1);
          codes.push(returnOrderIdNoS);
        }
        if (order.return_delivery_order_id) {
          codes.push(order.return_delivery_order_id);
        }
      }
      return codes;
    },
    checkingCodes() {
      const codes = [];
      if (!this.lastOrderScanned || !this.lastOrderScanned.tracking_id) {
        return codes;
      }
      if (this.lastOrderScanned.tracking_id) {
        codes.push(this.lastOrderScanned.tracking_id);
      }
      if (this.lastOrderScanned.delivery_order_id) {
        codes.push(this.lastOrderScanned.delivery_order_id);
      }
      if (this.lastOrderScanned.short_delivery_order_id) {
        codes.push(this.lastOrderScanned.short_delivery_order_id);
      }
      if (this.lastOrderScanned.return_order_id) {
        codes.push(this.lastOrderScanned.return_order_id);
        const returnOrderIdNoS = this.lastOrderScanned.return_order_id.slice(0, -1)
        codes.push(returnOrderIdNoS);
      }
      if (this.lastOrderScanned.return_delivery_order_id) {
        codes.push(this.lastOrderScanned.return_delivery_order_id);
      }
      return [...codes];
    },

    reverseOrders() {
      return [...this.orders].reverse();
    },
  },
  methods: {
    downloadExcelFile,
    cancelConfirm() {
      this.is_lost_seal = false;
      this.dialogConfirm = false;
      this.isLoading = false;
    },
    async updateReceive(order) {
      try {
        await httpClient.post("/goods-return/v1/update-receive", {
          id_goods_issue_detail: order.id,
          is_lost_seal: order.is_lost_seal ? 1 : 0,
          is_checked: order.is_checked ? 1 : 0,
          damage: order.damage,
          missing: order.missing,
          note: order.note,
          items: JSON.stringify(order.items || []),
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    confirmLostSeal(confirm_tracking) {
      this.tracking = confirm_tracking;
      this.getOrder();
    },
    async getOrder() {
      /*if (this.dialogConfirm) {
        if (![this.lastOrderScanned.tracking_id, this.lastOrderScanned.delivery_order_id, this.lastOrderScanned.short_delivery_order_id].includes(this.confirm_tracking)) {
          document.getElementById('error_sound_player').play()
          this.confirm_tracking = null
          return false
        }
        this.tracking = this.confirm_tracking
      }*/
      if (!this.tracking) {
        return false;
      }
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      if (this.tracking === BARCODE_MAT_NIEM_PHONG) {
        this.isLoading = false;
        this.tracking = null;
        if (!this.lastOrderScanned || !this.lastOrderScanned.tracking_id) {
          this.$vToastify.error(this.$t("messages.scan_order_first"));
          document.getElementById("error_sound_player").play();
          return false;
        }
        this.is_lost_seal = true;
        this.dialogConfirm = true;
        document.getElementById("success_sound_player").play();
        return false;
      }

      if (this.tracking === BARCODE_REMOVE) {
        this.isLoading = false;
        this.tracking = null;
        this.dialogConfirmRemove = true;
        return false;
      }

      if (this.is_lost_seal) {
        if (
          this.lastOrderScanned &&
          this.lastOrderScanned.id &&
          this.checkingCodes.includes(this.tracking)
        ) {
          const orders = [...this.orders];
          this.orders = orders.map((od) => {
            if (od.id === this.lastOrderScanned.id) {
              od.is_lost_seal = this.is_lost_seal;
              od.is_checked = false;
              this.updateReceive(od);
            }
            return od;
          });
          this.is_lost_seal = false;
          this.tracking = null;
          this.isLoading = false;
          this.dialogConfirm = false;
          document.getElementById("success_sound_player").play();
        } else {
          this.tracking = null;
          this.isLoading = false;
          this.$vToastify.error(this.$t("messages.wrong_tracking"));
          document.getElementById("error_sound_player").play();
        }
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-order-receive-return",
          {
            tracking: this.tracking,
          }
        );
        // data.is_lost_seal = this.is_lost_seal
        this.isLoading = false;
        this.tracking = null;

        const order = this.orders.find((o) => o.id === data.id);
        if (order) {
          this.isLoading = false;
          this.showDialogCheckingOrder(order);
          // this.$vToastify.error(this.$t('messages.order_scanned'))
          // document.getElementById('error_sound_player').play()
          return false;
        }
        data.missing = 0;
        data.damage = 0;
        const cData = await this.getOrderDetail(data);
        await this.updateReceive(cData);
        this.orders.push({ ...cData });
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.tracking = null;
        this.isLoading = false;
        this.is_lost_seal = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        switch (errMsg) {
          case "OrderNotFound":
            this.$vToastify.error(this.$t("labels.order_code_not_found"));
            document.getElementById(`MaDonKhongTonTai_player`).play();
            break;
          case "OrderNotStatus_0":
            this.$vToastify.error(this.$t("labels.status_invalid_0"));
            document.getElementById(`TrangThaiKhongHopLe_0_player`).play();
            break;
          case "OrderNotStatus_20":
            this.$vToastify.error(this.$t("labels.status_invalid_20"));
            document.getElementById(`TrangThaiKhongHopLe_20_player`).play();
            break;
          case "OrderNotStatus_35":
            this.$vToastify.error(this.$t("labels.status_invalid_35"));
            document.getElementById(`TrangThaiKhongHopLe_35_player`).play();
            break;
          case "OrderNotStatus_40":
            this.$vToastify.error(this.$t("labels.status_invalid_40"));
            document.getElementById(`TrangThaiKhongHopLe_40_player`).play();
            break;
          case "OrderNotStatus_60":
            this.$vToastify.error(this.$t("labels.status_invalid_60"));
            document.getElementById(`TrangThaiKhongHopLe_60_player`).play();
            break;
          case "OrderNotStatus_100":
            this.$vToastify.error(this.$t("labels.status_invalid_100"));
            document.getElementById(`TrangThaiKhongHopLe_100_player`).play();
            break;
          case "OrderNotStatus_160":
            this.$vToastify.error(this.$t("labels.status_invalid_160"));
            document.getElementById(`TrangThaiKhongHopLe_160_player`).play();
            break;
          case "OrderNotStatus_200":
            this.$vToastify.error(this.$t("labels.status_invalid_200"));
            document.getElementById(`TrangThaiKhongHopLe_200_player`).play();
            break;
          case "OrderNotStatus_220":
            this.$vToastify.error(this.$t("labels.status_invalid_220"));
            document.getElementById(`TrangThaiKhongHopLe_220_player`).play();
            break;
          case "OrderNotStatus_240":
            this.$vToastify.error(this.$t("labels.status_invalid_240"));
            document.getElementById(`TrangThaiKhongHopLe_240_player`).play();
            break;
          default:
            this.$vToastify.error(errMsg);
            document.getElementById("error_sound_player").play();
        }
      }
    },
    showDialogCheckingOrder(order) {
      this.checkingOrder = { ...order };
      this.dialogCheckingOrder = true;
    },
    async submitDialogCheckingOrder(order) {
      const orders = [...this.orders];
      this.orders = orders.map((od) => {
        if (od.id === order.id) {
          od = { ...order };
          this.updateReceive(od);
        }
        return od;
      });
    },
    async closeDialogCheckingOrder() {
      this.checkingOrder = {};
      this.dialogCheckingOrder = false;
      this.isLoading = false;
    },
    async confirmGroup(group) {
      if (group.is_not_checked > 0) {
        this.$vToastify.error(this.$t("messages.not_check_seal_damaged"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const groupOrders = [...group.orders];
      const beforeConfirmData = { ...group };
      const beforeOrders = [];
      for (let i = 0; i < groupOrders.length; i++) {
        const item = groupOrders[i];
        const checkDuplicate = beforeOrders.find(
          (d) => d.tracking_id === item.tracking_id
        );
        if (!checkDuplicate) {
          beforeOrders.push(item);
        }
      }
      beforeConfirmData.orders = [...beforeOrders];

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/create-session",
          beforeConfirmData
        );
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        await this.downloadFileReturn(data);
        const orders = [...this.orders];
        this.orders = orders.filter((od) => {
          return od.id !== (group.orders.find((o) => o.id === od.id) || {}).id;
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async cancelGroup(group) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-return/v1/cancel-receive", group);
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        const orders = [...this.orders];
        this.orders = orders.filter((od) => {
          return od.id !== (group.orders.find((o) => o.id === od.id) || {}).id;
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    cancelConfirmRemove() {
      this.dialogConfirmRemove = false;
    },
    showConfirmRemove() {
      this.dialogConfirmRemove = true;
    },
    confirmRemove(confirm_code) {
      const order = [...this.orders].find((od) =>
        [
          od.tracking_id,
          od.delivery_order_id,
          od.short_delivery_order_id,
          od.return_order_id,
          od.return_delivery_order_id,
        ].includes(confirm_code) || od.return_order_id == `S${confirm_code}`
      );
      if (!order) {
        this.$vToastify.error(this.$t("labels.order_not_found"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.cancelItem(order);
    },
    async cancelItem(order) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/goods-return/v1/cancel-receive-one-item",
          order
        );
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        const orders = [...this.orders];
        this.orders = orders.filter((od) => od.id !== order.id);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async getCurrentReceive() {
      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-current-receive"
        );
        this.orders = data.map((i) => ({
          ...i,
          items: JSON.parse(i.items || "[]"),
        }));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async downloadFileReturn(id) {
      const filename = `A4_bien_ban_nhan_hoan_${moment().unix()}`;
      this.downloadExcelFile(
        `/download/v1/return-session-file`,
        { id },
        filename
      )
        .then((res) => {
          console.log(res);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    async getOrderDetail(order) {
      order.items = JSON.parse(order.items || "[]");
      if (order.items && order.items.length > 0) {
        let total_quantity = 0;
        const items = order.items.map((d) => {
          total_quantity += d.quantity;
          return d;
        });
        order.items = [...items];
        return { ...order, total_sku: items.length, total_quantity };
      }
      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/get-order-detail",
          {
            id: order.id,
          }
        );
        let total_quantity = 0;
        const items = data.map((d) => {
          total_quantity += d.quantity;
          return { ...d, receive_quantity: 0, damage: 0, is_in_order: true };
        });
        order.items = [...items];
        return { ...order, total_sku: items.length, total_quantity };
      } catch (e) {
        return order;
      }
    },
  },
  mounted() {
    this.getCurrentReceive();
  },
};
</script>

<style scoped></style>
